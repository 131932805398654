.samples-list {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  &__item {
    padding: 32px 12px;

    display: flex;
    flex-direction: row;
  }
}

.filter {
  background: #d5d5d5;
  padding: 24px;

  display: flex;

  & > * {
    margin-right: 12px;
  }
}