.sample-item {

  &__header {
    text-align: center;
  }

  &__symmetry {
    margin: 32px 0;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 12px;
    }

    &__value {
      margin-top: 12px;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;

    margin-top: 24px;
  }

  &__form {
    padding: 12px;
  }

  &__titles {
    display: flex;
    justify-content: space-between;
  }

  &__picture {
    width: 250px;
    height: 120px;
    object-fit: cover;
  }

  &__confidence {
    display: flex;
    flex-direction: row;
  }

  &__ml-result {
    margin: 12px 0;
  }

}