.password-page {
  display: flex;

  width: 100vw;
  height: 100vh;

  justify-content: center;
  align-items: center;


  &__content {
    width: 400px;
  }
}